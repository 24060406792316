import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import SeoData from "../seoData.json"
import { generateUID } from "../utils/helpers"
import { useI18next } from "gatsby-plugin-react-i18next"

function Seo({
  description,
  lang,
  meta,
  title,
  seoIdentifier,
  metaImage,
  hideSlogan,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
            imageWidth
            imageHeight
          }
        }
      }
    `
  )

  const getIdentifierSeoData = type => {
    return SeoData?.[seoIdentifier]?.[lang]?.[type.toString()]
  }

  const { originalPath, language } = useI18next()

  const metaData = {
    title: title || getIdentifierSeoData("title") || site.siteMetadata?.title,
    description:
      description ||
      getIdentifierSeoData("description") ||
      site.siteMetadata?.description,
  }

  const slogan =
    getIdentifierSeoData("slogan") && getIdentifierSeoData("slogan")

  const disableSlogan =
    hideSlogan || getIdentifierSeoData("disableSlogan") == "true" ? true : false

  const image = metaImage?.src
    ? {
        src: `${site.siteMetadata.siteUrl}${metaImage.src}`,
        width: metaImage.width,
        height: metaImage.height,
      }
    : {
        src: `${site.siteMetadata.image}`,
        width: site.siteMetadata.imageWidth,
        height: site.siteMetadata.imageHeight,
      }

  const path = React.useMemo(
    () => seoIdentifier || originalPath.slice(1) || null,
    [originalPath, seoIdentifier]
  )

  /**
   * @see https://github.com/nfl/react-helmet/issues/342
   *
   * Helmet segnala come errore innestare <tag> in <Helmet> ma non accetta <React.Fragment>
   * @see https://github.com/silvenon/silvenon.com/commit/32800accec4d55ba103d23cb6358c01ee0c94aac
   *
   * Ritornare un Array direttamente nel <Helmet> sembra essere unica soluzione
   */

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaData.title}
      titleTemplate={!disableSlogan && slogan ? `%s | ${slogan}` : null}
    >
      {path != null
        ? [
            <link
              href={`${site.siteMetadata.siteUrl}${path}`}
              rel="alternate"
              hrefLang="it-IT"
              key={generateUID()}
            />,
            <link
              href={`${site.siteMetadata.siteUrl}/en${path}`}
              rel="alternate"
              hrefLang="en-GB"
              key={generateUID()}
            />,
            <link
              href={`${site.siteMetadata.siteUrl}/fr${path}`}
              rel="alternate"
              hrefLang="fr-FR"
              key={generateUID()}
            />,
            <link
              href={`${site.siteMetadata.siteUrl}${
                language === "it" ? "" : `/${language}`
              }${path}`}
              rel="canonical"
              key={generateUID()}
            />,
          ]
        : null}
      <meta name="description" content={metaData.description} />
      <meta property="og:title" content={metaData.title} />
      <meta property="og:description" content={metaData.description} />
      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary" />
      <meta
        property="twitter:creator"
        content={site.siteMetadata?.author || ``}
      />
      <meta property="twitter:title" content={metaData.title} />
      <meta property="twitter:description" content={metaData.description} />
      <meta property="og:image" content={image.src} />
      <meta property="og:image:url" content={image.src} />
      <meta property="og:image:width" content={image.src} />
      <meta property="og:image:height" content={image.src} />
      <meta property="twitter:card" content={image.src} />
      <meta property="twitter:image" content={image.src} />
      <meta property="og:locale:alternate" content={["it_IT", "en_US"]} />
      {meta && meta.length > 0 && meta != null
        ? meta.map(el => {
            return (
              <meta
                property={el.property}
                content={el.content}
                key={generateUID()}
              />
            )
          })
        : null}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  seoIdentifier: PropTypes.string.isRequired,
  originalPath: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    widht: PropTypes.string.isRequired,
  }),
}

export default Seo
