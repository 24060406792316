import * as React from "react"
import { navigate } from "@reach/router"
import { useHasMounted } from "@youngagency/young-ui"
import Seo from "../components/seo"
import { useI18next } from "gatsby-plugin-react-i18next"

const PlayStore = () => {
  const { t } = useI18next()
  const hasMounted = useHasMounted()
  React.useEffect(() => {
    if (hasMounted) {
      navigate(
        "https://play.google.com/store/apps/details?id=com.youngplatform.young",
        { replace: true }
      )
    }
  }, [hasMounted])
  return (
    <React.Fragment>
      <Seo title={t("404.seo.title")} description={t("404.seo.description")} />
    </React.Fragment>
  )
}

export default PlayStore
